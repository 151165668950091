import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const LanguageSelector = (props) => {
    const {i18n} = useTranslation()

    function changeLanguage(event){
        props.setDarkmode(!props.darkmode);
    }

    return (

        <label className="switch mb-0 ml-3">
            <input type="checkbox" className="switch__input"
                   checked={props.darkmode}
                   onChange={changeLanguage}
            />
            <div className="switch__wrapper">
                <div className="switch__knob">
                    <img className="switch__first-icon" src="/moon.svg" alt=""/>
                    <img className="switch__second" src="/sun.svg" alt=""/>
                </div>
            </div>
        </label>
    )
}

export default LanguageSelector