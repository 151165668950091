import React, {useState} from 'react';

import {
  EnvelopeOpen,
  PhoneCall,
  WhatsappLogo,
} from 'phosphor-react';

//Bootstrap imports
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const postmark = require('postmark');

function ContactForm() {

  return (

      <section className={'content_wrapper bg-light'}>
        <Container>
          <Row>
            <Col>
              <h1 className="text-dark">Interesse? Neem contact op</h1>
              <p className="w-75 mb-4">
                We staan voor u klaar als u vragen of opmerkingen heeft. We
                bespreken graag de mogelijkheden en nemen samen de beste opties
                door. U kunt ook uw gegevens via mail of whatsapp achterlaten en
                dan zullen we u contacteren.
              </p>
              <p>
                <a href="tel:06 49 00 57 49" target="_blank"><PhoneCall size={48}/> 06 49 00 57 49</a>
              </p>
              <p>

                <a href="https://wa.me/31649005749" target="_blank"><WhatsappLogo size={48}/> 06 49 00 57 49</a>
              </p>
              <p>
                <a href="mailto:Ellard@edk.media" target="_blank"><EnvelopeOpen size={48}/> Ellard@edk.media</a>
              </p>
              <p>
                KVK 74620657
              </p>


            </Col>
          </Row>

        </Container>
      </section>

  );
}

export default ContactForm;