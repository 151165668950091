import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import Title from '../Includes/Title';
import List from './List';
import items from './../Includes/portfolioItems';
import ScrollableAnchor from 'react-scrollable-anchor';
import {useTranslation} from 'react-i18next';
import {useSpring, useTrail, animated} from 'react-spring';

function Portfolio(props) {
  const {t, i18n} = useTranslation();
  const anim = useSpring({opacity: 1, from: {opacity: 0}});
  return (
      <section className={'content_wrapper'}>
        <ScrollableAnchor id={'portfolio'}>
          <Container>
            <Title
                title={t('portfolio.title.title')}
                subtitleC={t('portfolio.title.subtitleC')}
                subtitle={t('portfolio.title.subtitle')}
                margin=""
            />

            <Row>
              {props.posts.map((post, index) => <Regular baseUrl={props.baseUrl} index={index} {...post} key={post.id}/>)}
            </Row>

          </Container>
        </ScrollableAnchor>
      </section>
  );
}

function Regular(props) {
  return (
      <Col className="mb-5 portfolio-item--wrapper" sm={12}>
        <div className="portfolio-item">
          <div className="portfolio-item__details">
            <h3 className="text-dark">{props.title}</h3>
            <p className="mt-4">{props.description}</p>

            <a className="btn btn-primary mt-4" href={props.url} target="_blank">Open website</a>
          </div>

          <div className="portfolio-item__image" style={{backgroundImage: `url(${props.baseUrl}${props.image.url})`}}>

          </div>
        </div>
      </Col>
  );
}

export default Portfolio;