import React from 'react';
import AboutMe from "./About me/AboutMe";
import Experience from "./Experience";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

const EllardPage = () => {
    return (
        <section id={"cv"}>
            <Container fluid={true}>
                <Row>
                    <Col sm={12} md={12} lg={3}>
                        <AboutMe/>
                    </Col>
                    <Col sm={12} md={12} lg={9}>
                        <Experience/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default EllardPage;