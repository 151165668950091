import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import logo from './../logo.svg';
import websites from './../images/undraw_building_websites_i78t.svg';
import menuitems from './Includes/menuitems';
import ScrollableAnchor from 'react-scrollable-anchor';
import {Trans, useTranslation} from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faDesktop,
  faMobileAlt,
  faTabletAlt,
  faUserTie,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import {useSpring, animated, config} from 'react-spring';
//Bootstrap imports
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

function Header(props) {
  const [menu, setMenu] = useState(false);
  const [freelance, setFreelance] = useState(false);
  const {t} = useTranslation();

  /*const fade = useSpring({
    config: config.wobbly,
    from: {
      transform: 'translate(100%)',
      opacity: 0,
    },
    to: {
      transform: showWebsite ? 'translate(0)' : 'translate(100%)',
      opacity: showWebsite ? 1 : 0,
    },
  });*/

  function toggleMenu(e) {
    e.preventDefault();
    setMenu(!menu);
  }

  const menuitemsA = menuitems({
    'home': t('header.nav.home'),
    'services': t('header.nav.services'),
    'portfolio': t('header.nav.portfolio'),
    'contact': t('header.nav.contact'),
  });
  let navitems = menuitemsA.map(
      item => <a href={item.destination} key={item.name}
                 className={item.button}>{item.name}</a>);
  return (
      <ScrollableAnchor id={'home'}>
        <header className="header">
          <div id="sub-header">
            <Container>
              <Row>
                <Col xs={2}>
                  <div className="logo">
                    <img src={logo} alt="EDK Media logo"/>
                    <h1 className="d-none d-lg-block">EDK Media</h1>
                  </div>
                </Col>
                <Col xs={10}>
                  <nav>
                    {navitems}
                    <a className="hamburger" onClick={(e) => toggleMenu(e)}
                       href="#"><FontAwesomeIcon
                        icon={faBars}/></a>
                    <LanguageSelector darkmode={props.darkmode} setDarkmode={props.setDarkmode}/>
                  </nav>
                  <div id="mySidenav"
                       className={menu ? 'menu-open sidenav' : 'sidenav'}>
                    <a href="/" className="closebtn"
                       onClick={(e) => toggleMenu(e)}>&times;</a>
                    {navitems}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <Container className="py-5">
            <Row className="mb-5">
              <Col>
                <Row className={`${freelance ?
                    'header__flip' :
                    ''} m-t-3 header__content`}>
                  <Col sm={9} className="header__website">
                    <Trans i18nKey='header.message'>
                      <h1 className="text-dark">Online </h1>
                      <h1 className="text-dark">Zonder</h1>
                      <h1 className="text-dark">Zorgen</h1>
                    </Trans>
                  </Col>

                </Row>
                <Row>
                  <Col className="header-buttons">
                    <a href="/#contact" className="btn btn-primary mr-4">{t(
                        'header.button')}</a>
                    <a href="/#diensten" className="btn btn-secondary mr-4">{t(
                        'header.services')}</a>
                  </Col>
                </Row>
              </Col>
              <Col className="d-none d-lg-block">
                <img src={websites} alt="People building a website"/>
              </Col>
            </Row>
          </Container>
        </header>
      </ScrollableAnchor>
  );
}

export default Header;