import React, {useEffect, Suspense, useState} from 'react';
import ReactGa from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Header from './components/Header.jsx';
import Qualities from './components/Qualities.jsx';
import Services from './components/Services.jsx';
import ContactForm from './components/Contact_form';
import Portfolio from './components/Portfolio/Portfolio';
import './i18n';
import EllardPage from './components/CV/EllardPage';
import * as axios from 'axios';
import api from './api';

function App() {
  const [qualities, setQualities] = useState([]);
  const [darkmode, setDarkmode] = useState(false);
  const [portfolio, setPortfolio] = useState([]);
  let baseUrl;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseUrl = 'http://localhost:1337';
  } else {
    baseUrl = 'https://api.edk.media';
  }

  useEffect(() => {
    ReactGa.initialize('UA-160970036-1');
    //Report page to Google Analytics
    ReactGa.pageview(window.location.pathname + window.location.search);
    fetchQualities();
    fetchPortfolios();
  }, []);

  const fetchQualities = async () => {
    const result = await axios.get(api.getQualities);

    setQualities(result.data);
  };

  const fetchPortfolios = async () => {
    const result = await axios.get(api.getPortfolios);
    setPortfolio(result.data);
  };



  return (
      <Router>
        <Suspense fallback={null}>
          <div className={darkmode ? "dark-theme" : ""}>

            <Switch>
              <Route path="/ellard">
                <EllardPage/>
              </Route>
              <Route path="/">
                <Header darkmode={darkmode} setDarkmode={setDarkmode}/>
                <Qualities posts={qualities} baseUrl={baseUrl}/>
                <Services/>
                <Portfolio posts={portfolio} baseUrl={baseUrl}/>
                <ContactForm/>
              </Route>
            </Switch>
          </div>
        </Suspense>
      </Router>
  );
}

export default App;
