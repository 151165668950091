import React, {useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboardCheck, faDatabase, faFlagCheckered, faStopwatch, faUsers} from '@fortawesome/free-solid-svg-icons';
import {faGit, faHtml5, faJs, faReact, faWordpress, faPhp} from '@fortawesome/free-brands-svg-icons';
import Slider from 'react-input-slider';

const Experience = () => {
    const [knowledge, setKnowledge] = useState(false);

    const changeSkill = () => {
        setKnowledge(true);

    };

    React.useEffect(() => {
        if (knowledge) {
            alert("You cannot change my wisdom by simply dragging a slider. You will have to teach me.");
        }
    }, [knowledge]);


    return (
        <div className={"experience"}>
            <Row>
                <Col className="mb-5" sm={8}>
                    <h2 className="textdark mt-0">My current goals</h2>
                    <p className={"fs-small"}>It is my main goal to grow my business. To do so I require more knowledge
                        on how to apply tech solutions to business problems. I want to build up more experience on
                        communicating with clients so that I know how to best suit their needs.
                    </p>
                    <p className={"fs-small"}>
                        Figuring out a good workflow involving <a href="https://strapi.io/">Strapi</a> and <a
                        href="https://reactjs.org/">React</a> as an alternative to WordPress. Previously I tried hacking
                        WordPress to work with Vue.js and React, while this is a improvement on the frontend side of
                        things I am still relying on WordPress's legacy API to fetch posts. Which is not fun.
                    </p>
                    <p className={"fs-small"}>
                        Becoming more efficient. I was inspired by <a href="https://www.calnewport.com/">Cal
                        Newport's</a> book "Deep Work" to really step up my game and take my development career to the
                        next level. The first step I am currently taking is reviewing my tool stack and utilizing its benefits to the max.
                    </p>
                </Col>
                <Col className="mb-5" sm={12}>
                    <h2 className="textdark mt-0">Experience</h2>
                    <div className="experience__item">
                        <div>
                            <h5 className="textdark"><a href="https://wijzijnpuik.nl/">Puik</a> <span
                                className="my-2">-</span> Doetinchem.</h5> <h5 className="textdark fw-regular">Dec
                            2016 <FontAwesomeIcon icon={faStopwatch}/> Apr 2016</h5>
                        </div>
                        <div>
                            <h6 className="textdark fw-regular">Internee Web developer</h6>
                        </div>
                    </div>

                    <div className="experience__item">
                        <div>
                            <h5 className="textdark"><a
                                href="https://www.youtube.com/user/TheDutchTerms">TheDutchTerms</a> <span
                                className="my-2">-</span> Bovensmilde.</h5> <h5 className="textdark fw-regular">Feb
                            2016 <FontAwesomeIcon icon={faStopwatch}/> Apr 2017</h5>
                        </div>
                        <div>
                            <h6 className="textdark fw-regular">Internee Web/Application developer</h6>
                        </div>
                    </div>

                    <div className="experience__item">
                        <div>
                            <h5 className="textdark"><a href="https://iseeiknow.com/">iSee iKnow</a> <span
                                className="my-2">-</span> Deventer.</h5> <h5 className="textdark fw-regular">May
                            2018 <FontAwesomeIcon icon={faStopwatch}/> Jan 2019</h5>
                        </div>
                        <div>
                            <h6 className="textdark fw-regular">Web developer</h6>
                        </div>
                    </div>

                    <div className="experience__item">
                        <div>
                            <h5 className="textdark"><a href="http://animavideogame.com">Anima</a> <span
                                className="my-2">-</span> Global.</h5> <h5 className="textdark fw-regular">April
                            2019 <FontAwesomeIcon icon={faStopwatch}/> Now</h5>
                        </div>
                        <div>
                            <h6 className="textdark fw-regular">Programming Lead, Game developer</h6>
                        </div>
                    </div>

                    <div className="experience__item">
                        <div>
                            <h5 className="textdark"><a href="https://edk.media">EDK Media</a> <span
                                className="my-2">-</span> Arnhem.</h5> <h5 className="textdark fw-regular">Feb
                            2019 <FontAwesomeIcon icon={faStopwatch}/> Now</h5>
                        </div>
                        <div>
                            <h6 className="textdark fw-regular">CEO, Full stack developer</h6>
                        </div>
                    </div>
                </Col>

                <Col className="mb-5 skills" sm={7}>
                    <h2 className="textdark mt-0">Technical Skills</h2>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor="react">HTML5 & CSS3(ITCSS) <FontAwesomeIcon
                            icon={faHtml5}/></label>
                        <div id="react">
                            <Slider
                                axis="x"
                                x={98}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor="react">Javascript <FontAwesomeIcon
                            icon={faJs}/></label>
                        <div id="react">
                            <Slider
                                axis="x"
                                x={97}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor="react">React & Redux <FontAwesomeIcon icon={faReact}/></label>
                        <div id="react">
                            <Slider
                                axis="x"
                                x={95}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label">Express</label>
                        <div>
                            <Slider
                                axis="x"
                                x={91}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label">Git <FontAwesomeIcon icon={faGit}/></label>
                        <div>
                            <Slider
                                axis="x"
                                x={88}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label">SQL & MYSQL <FontAwesomeIcon icon={faDatabase}/></label>
                        <div>
                            <Slider
                                axis="x"
                                x={78}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label">Mongo & Mongoose</label>
                        <div>
                            <Slider
                                axis="x"
                                x={75}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor="react">Wordpress<FontAwesomeIcon icon={faWordpress}/></label>
                        <div id="react">
                            <Slider
                                axis="x"
                                x={70}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor=".net">.NET</label>
                        <div id=".net">
                            <Slider
                                axis="x"
                                x={75}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor=".net">PHP <FontAwesomeIcon icon={faPhp}/></label>
                        <div id=".net">
                            <Slider
                                axis="x"
                                x={80}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col className="mb-5 skills" sm={7}>
                    <h2 className="textdark mt-0">Communicative Skills</h2>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor="react">MoSCoW <FontAwesomeIcon
                            icon={faFlagCheckered}/></label>
                        <div id="react">
                            <Slider
                                axis="x"
                                x={100}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor="react">SCRUM <FontAwesomeIcon
                            icon={faClipboardCheck}/></label>
                        <div id="react">
                            <Slider
                                axis="x"
                                x={98}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="skills__item">
                        <label className="skills__label" htmlFor="react">Leading (Programming) <FontAwesomeIcon
                            icon={faUsers}/></label>
                        <div id="react">
                            <Slider
                                axis="x"
                                x={75}
                                onChange={() => changeSkill()}
                                styles={{
                                    track: {
                                        backgroundColor: 'white',
                                        width: '100%'
                                    },
                                    active: {
                                        backgroundColor: '#003263'
                                    },
                                    disabled: {
                                        opacity: 0.5
                                    }
                                }}
                            />
                        </div>
                    </div>

                </Col>
            </Row>

        </div>
    );
};

export default Experience;