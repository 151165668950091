import React from 'react';
import profilePicture from "../../../images/profilepicture.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCompass,
    faEnvelopeOpen,
    faPhone,
    faGamepad,
    faRunning,
    faBookOpen,
    faLanguage,
    faStopwatch, faGraduationCap
} from '@fortawesome/free-solid-svg-icons';
import {faUnity} from '@fortawesome/free-brands-svg-icons';

const AboutMe = () => {
    return (
        <div className={"about-me"}>
            <Row className="justify-content-sm-center justify-content-lg-none">
                <Col lg={12}>
                    <div className="about-me__photo">
                        <img src={profilePicture} alt="A picture of Ellard"/>
                    </div>
                </Col>
                <Col xs={12} sm={8} lg={12} className="mb-5">
                    <h2 className="textlight">Ellard de Koeijer</h2>
                    <h6 className="textlight">Web developer & Entrepreneur</h6>
                </Col>
                <div className="w-100"></div>

                <Col xs={12} sm={8} lg={12} className="textlight about-me__contact mb-5">
                    <h2 className="textlight">Contact</h2>
                    <div className="about-me__contact-item">
                        <FontAwesomeIcon icon={faCompass}></FontAwesomeIcon> Arnhem Presikhaaf, Gelderland
                    </div>
                    <div className="about-me__contact-item">
                        <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> 06 - 49 00 57 49
                    </div>
                    <div className="about-me__contact-item">
                        <FontAwesomeIcon icon={faEnvelopeOpen}></FontAwesomeIcon> Ellard@edk.media
                    </div>

                    <div className="about-me__contact-item">
                        <FontAwesomeIcon icon={faLanguage}></FontAwesomeIcon> Dutch & English
                    </div>
                </Col>
                <div className="w-100"></div>
                <Col xs={12} sm={8} lg={12} className="textlight mb-5">
                    <h2 className="textlight">Hobbies</h2>
                    <div className="about-me__hobbies">
                        <div className="about-me__contact-item">
                            <FontAwesomeIcon icon={faGamepad}></FontAwesomeIcon>
                        </div>
                        <div className="about-me__contact-item">
                            <FontAwesomeIcon icon={faRunning}></FontAwesomeIcon>
                        </div>
                        <div className="about-me__contact-item">
                            <FontAwesomeIcon icon={faBookOpen}></FontAwesomeIcon>
                        </div>
                        <div className="about-me__contact-item">
                            <FontAwesomeIcon icon={faUnity}></FontAwesomeIcon>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={8} lg={12} className="textlight mb-5">
                    <h2 className="textlight mt-0">Education</h2>
                    <div className="experience__item experience__item--light">
                        <div>
                            <h6 className="textlight">HBO ICT - Web development</h6>
                        </div>
                        <div><h6 className="textlight fw-regular">
                            HAN University of Applied Sciences <span
                            className="my-2">-</span> Arnhem.
                        </h6></div>
                        <div>
                            <h6 className="textlight fw-regular">2017 <FontAwesomeIcon icon={faStopwatch}/> Now</h6>
                        </div>
                    </div>
                    <div className="experience__item experience__item--light">
                        <div>
                            <h6 className="textlight">MBO Media development <FontAwesomeIcon className="textSuccess" icon={faGraduationCap}/></h6>
                        </div>
                        <div><h6 className="textlight fw-regular">
                            Graafschap college <span
                            className="my-2">-</span> Doetinchem.
                        </h6></div>
                        <div>
                            <h6 className="textlight fw-regular">2014 <FontAwesomeIcon icon={faStopwatch}/> 2017</h6>
                        </div>
                    </div>
                    <div className="experience__item experience__item--light">
                        <div>
                            <h6 className="textlight">VMBO KB <FontAwesomeIcon className="textSuccess" icon={faGraduationCap}/></h6>
                        </div>
                        <div><h6 className="textlight fw-regular">
                            AOC-OOST <span
                            className="my-2">-</span> Doetinchem.
                        </h6></div>
                        <div>
                            <h6 className="textlight fw-regular">2010 <FontAwesomeIcon icon={faStopwatch}/> 2014</h6>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default AboutMe;