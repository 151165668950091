import React from 'react';
import laptop from './../images/laptop.png';
//Bootstrap imports
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Title from './Includes/Title';
import {useTranslation} from 'react-i18next';
import modern from './../images/web_developer.svg';
import team from './../images/team_building_.svg';
import safe from './../images/password.svg';

function Qualities(props) {
  const {t} = useTranslation();
  return (
      <section className={'content_wrapper'}>
        <Container>
          <Row>
            <Col>
              <Title
                  title={t('attention.title.title')}
                  subtitleC={t('attention.title.subtitleC')}
                  subtitle={t('attention.title.subtitle')}
                  margin=""
              />
            </Col>
          </Row>

          {props.posts.map((post, index) => <Quality baseUrl={props.baseUrl} index={index} {...post} key={post.id}/>)}

        </Container>
      </section>
  );
}

function Quality(props) {
  return (
      <div className="attention-item">
        <Row className={props.index%2 == 0 ? "flex-row-reverse" : ""}>
          <Col sm={12} md={6} className={props.index%2 == 0 ? "mb-md-5 pb-md-5 pl-lg-5" : "mb-md-5"}>
            <h2 className="text-dark mb-5">{props.title}</h2>
            <p>
              {props.description}
            </p>
          </Col>
          <Col sm={12} md={6} className={props.index%2 == 0 ? "d-flex justify-content-start" : "d-flex justify-content-end "}>
            <div className="w-100 d-block d-lg-none">
              <img src={props.baseUrl+ props.visual.url} alt={props.visual.alternativeText} />
            </div>
            <div className="w-75 d-none d-lg-block">
              <img src={props.baseUrl+ props.visual.url} alt={props.visual.alternativeText} />
            </div>
          </Col>
        </Row>
      </div>
  );
}

export default Qualities;