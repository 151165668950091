function menuitems(translatedItems) {
    return [
        {
            name: translatedItems.home,
            destination: '#home'
        },
        {
            name: translatedItems.services,
            destination: '#diensten'
        },
        {
            name: translatedItems.portfolio,
            destination: '#portfolio'
        },
        {
            name: translatedItems.contact,
            destination: '#contact'
        }
    ]
}

export default menuitems