let baseUrl;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseUrl = 'http://localhost:1337';

} else {
    baseUrl = 'https://api.edk.media'
}

//Exports all endpoints.
export default {
    /**
     * EMAIL
     */
    sendEmail: () => `${baseUrl}/email`,
    getQualities: `${baseUrl}/qualities`,
    getPortfolios: `${baseUrl}/portfolios`
};
