import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Title(props) {
    return (
    <Row>
        <Col className="title_component">
            <h1 style={{whiteSpace: 'pre-wrap'}} className="text-dark">{props.title}</h1>
            <p className="textSecondary fs-medium bold">{props.subtitleC} <span className="textdark">{props.subtitle}</span></p>
        </Col>
    </Row>
    );
}

export default Title;
