import React, {useState} from 'react';
import Title from './Includes/Title.jsx';
//Bootstrap imports
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {
  HardDrives,
  CheckCircle,
  LockKey,
  ChatDots,
  DeviceMobileCamera,
  PaintBrushBroad,
  Browser,
  Cloud,
  ClockCounterClockwise,
  FastForwardCircle,
} from 'phosphor-react';
import ScrollableAnchor from 'react-scrollable-anchor';
import {Trans, useTranslation} from 'react-i18next';
import {useSpring, animated, config} from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faReact} from '@fortawesome/free-brands-svg-icons';

function Services(props) {
  const {t, i18n} = useTranslation();

  const [showWebsite, setShowWebsite] = useState(false);
  const fade = useSpring({
    config: config.wobbly,
    from: {
      transform: 'translate(100%)',
      opacity: 0,
    },
    to: {
      transform: showWebsite ? 'translate(0)' : 'translate(100%)',
      opacity: showWebsite ? 1 : 0,
    },
  });

  const check = useSpring({
    config: {duration: 150, ...config.stiff},
    from: {
      transform: 'scale(1)',
    },
    to: async (next, cancel) => {
      await next(
          {transform: showWebsite ? 'scale(1.5)' : 'scale(1)'},
      );
      await next({transform: 'scale(1)'});
    },
    delay: 1000,
  });

  function activateIcons(isVisible) {
    setShowWebsite(isVisible);
  }

  function activateFold() {

  }

  return (
      <section className={'content_wrapper bg-light'}>
        <ScrollableAnchor id={'diensten'}>
          <Container>
            <Title
                title={t('services.title.title')}
                subtitleC={t('services.title.subtitleC')}
                subtitle={t('services.title.subtitle')}
                margin=""
            />
            <VisibilitySensor active={!showWebsite} onChange={activateIcons}>
              <div className="row">
                <div className="col-md-6 col-12 mb-5 mb-lg-0">
                  <div className="service h-100">
                    <h2 className="text-dark">Websites</h2>
                    <p className="fs-medium">
                      Wij maken prachtige websites en jij wilt een product of
                      service op het web laten stralen. Laten
                      we <strong>elkaar</strong> helpen.
                    </p>
                    <p className="fs-medium">
                      Wat is jouw doel? Bespreek de mogelijke realisaties geheel
                      vrijblijvend door contact op te nemen.
                    </p>
                    <div className="row">
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <HardDrives size={48}/>
                          </div>
                          <div>
                            Hosting
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>

                      </Col>
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <LockKey size={48}/>
                          </div>
                          <div>
                            SSL
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>

                      </Col>
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <ChatDots size={48}/>
                          </div>
                          <div>
                            Support
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>

                      </Col>
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <DeviceMobileCamera size={48}/>
                          </div>
                          <div>
                            Responsief
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>

                      </Col>
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <PaintBrushBroad size={48}/>
                          </div>
                          <div>
                            Prachtig
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>

                      </Col>
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <Browser size={48}/>
                          </div>
                          <div>
                            CMS
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>

                      </Col>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="service h-100">
                    <h2 className="text-dark">Hosting</h2>
                    <h5 className="text-dark">Online zonder zorgen</h5>
                    <p className="fs-medium">Websites van EDK Media zijn voorzien van hosting zodat
                      jij je geen zorgen hoeft te maken.</p>
                    <p className="fs-medium">Wij zorgen ervoor
                      dat de website online staat. En als je contact wilt
                      opnemen dan kan dat direct, niet eerst door zo'n
                      chatrobot of wachtlijst heen!
                    </p>
                    <div className="row">
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <Cloud size={48}/>
                          </div>
                          <div>
                            99% uptime
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>

                      </Col>
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <ClockCounterClockwise size={48}/>
                          </div>
                          <div>
                            Backups
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>

                      </Col>
                      <Col sm={4}>
                        <animated.div className="service__item" style={fade}>
                          <div>
                            <FastForwardCircle size={48}/>
                          </div>
                          <div>
                            Razendsnel
                          </div>
                          <animated.div className="service-item__checkmark"
                                        style={check}>
                            <CheckCircle size={24}/>
                          </animated.div>
                        </animated.div>
                      </Col>
                    </div>
                  </div>
                </div>
                <Col sm={12}>
                  <div className="mt-5 d-flex justify-content-center">
                    <a className="btn btn-primary" href="#contact">Kom erachter</a>
                  </div>
                </Col>
              </div>
            </VisibilitySensor>
          </Container>
        </ScrollableAnchor>
      </section>
  );
}

export default Services;